/* eslint-disable */
/* Warning: this file is autogerated, any changes would be lost */
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import WpAcfImageBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpCoreParagraphBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'
import WpCoreFreeformBlock from '/home/ubuntu/michelatsch-website/frontend/node_modules/gatsby-theme-wordpress-gutenberg/src/components/unknown-block.js'



const Blocks = ({ blocks = [], previewUUID }) => {
  return (
    <>
      {useMemo(() => {
        
        return blocks
      }, [blocks, previewUUID]).map((block, i) => {
        const children = block.innerBlocks ? <Blocks blocks={block.innerBlocks} previewUUID={previewUUID} /> : null;

        if (block.__typename === 'WpAcfImageBlock') {
            return <WpAcfImageBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpCoreParagraphBlock') {
            return <WpCoreParagraphBlock {...block} children={children} key={i} />
          }
        if (block.__typename === 'WpCoreFreeformBlock') {
            return <WpCoreFreeformBlock {...block} children={children} key={i} />
          }

        return null;
      })}
    </>
  );
};

export default ({ previewUUID }) => {

  const { wpProject: { blocks } } = useStaticQuery(graphql`
    fragment Blocks408 on WpBlock { __typename ...ThemeWordpressGutenbergUnknownBlock  }
    query {
      wpProject(id: {eq: "408"}) {
        blocks {
          ...Blocks408
        }
      }
    }`)

  return <Blocks blocks={blocks} previewUUID={previewUUID} />
}
